import React, { FC } from 'react'
import { Container, NavDropdown, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { texts } from '../utils/texts'
import { COLLECTIONS, PATHS } from '../utils/shared/constants'
import { useFirebaseDoc } from '../hooks/firebaseHookst'
import { User } from '../models/shared/User'
import { useAuth } from 'reactfire'
import { calculateUserBilling } from '../utils/shared/logic'

interface IProps {
    public?: boolean
    handleLogout?: () => void
}

export const Header: FC<IProps> = (props) => {
    const currentUser = useAuth().currentUser;
    const [user] = useFirebaseDoc<User>(`${COLLECTIONS.USERS}/${currentUser?.uid}`);
    const [remainingTrialDays, plan] = calculateUserBilling(user!);
    const renderItems = () => {
        if (props.public) {
            return <></>
        }
        return <>
            <NavDropdown title={texts.account} menuVariant='right' >
                <NavDropdown.Item as={Link} to={`/${PATHS.BILLING}`}><i className='bi bi-credit-card me-2' />Billing</NavDropdown.Item>
                <NavDropdown.Item onClick={props.handleLogout}><i className='bi bi-box-arrow-right me-2' />{texts.logout}</NavDropdown.Item>
            </NavDropdown>
        </>
    }

    const aboutToExpire = remainingTrialDays < 5;
    return (
        <>
            <Navbar expand="lg" className="navbar-dark bg-primary">
                <Container>
                    <Link to={'/'}><Navbar.Brand >⭐<span className='ms-1 d-none d-md-inline'>{texts.brand}</span></Navbar.Brand></Link>
                    {renderItems()}
                </Container>
            </Navbar>
            {!plan &&
                <Link to={`/${PATHS.BILLING}`} className='link-underline-secondary'>
                    <p className={`center p-2 w-100 ${aboutToExpire ? 'bg-danger' : 'bg-dark'} text-white`}>
                        {remainingTrialDays > 0
                            ? `Your trial has ${remainingTrialDays.toFixed(0)} days remaining.` + (aboutToExpire ? ' Please upgrade your account to avoid service interruption.' : '')
                            : 'Your trial has ended. Please upgrade your account to reenable widgets.'}
                    </p>
                </Link>
            }
        </>
    )
}
