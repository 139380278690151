import { SearchPlacesResponse } from "../models/shared/api";
import { Source } from "../models/shared/Source";
import config from "./config"
import { QUERY_PARAMS } from "./shared/constants";


export const searchPlaces = async (token: string, searchTerm: string, source: Source): Promise<SearchPlacesResponse> => {
    const endpoint = `${config.functions.searchPlaces}?searchTerm=${searchTerm}&source=${source}`;
    const result = await fetch(endpoint, { headers: { Authorization: `Bearer ${token}` } });
    if (!result.ok) {
        throw new Error(await result.text());
    }

    return result.json();
}

export const ensurePlaceLoaded = async (token: string, placeId: string, placeName: string, source: Source) => {
    const endpoint = `${config.functions.ensurePlaceLoaded}?placeId=${placeId}&placeName=${placeName}&source=${source}`;
    const result = await fetch(endpoint, { headers: { Authorization: `Bearer ${token}` } });
    if (!result.ok) {
        throw new Error(await result.text());
    }
}

export const initiatePlanUpdate = async (token: string, planId: string, annually: boolean) => {
    const currentLocation = `${window.location.origin}${window.location.pathname}?${QUERY_PARAMS.PLAN_UPDATED}=true`;
    const endpoint = `${config.functions.createPaymentLink}?planId=${planId}&returnUrl=${currentLocation}&annually=${annually}`;
    const result = await fetch(endpoint, { headers: { Authorization: `Bearer ${token}` } });
    if (!result.ok) {
        throw new Error(await result.text());
    }

    return result.json();
};

export const ensureUserPlan = async (token: string) => {
    const endpoint = config.functions.ensureUserPlan;
    const result = await fetch(endpoint, { headers: { Authorization: `Bearer ${token}` } });
    if (!result.ok) {
        throw new Error(await result.text());
    }
}

export const sendSlackMessage = async (token: string, message: string) => {
    const endpoint = `${config.functions.sendSlackMessage}`;
    const result = await fetch(endpoint, { method: 'POST', headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }, body: JSON.stringify({ message }) });
    if (!result.ok) {
        throw new Error(await result.text());
    }
}

export const getCustomerInvoices = async (token: string) => {
    const endpoint = `${config.functions.getCustomerInvoices}`;
    const result = await fetch(endpoint, { headers: { Authorization: `Bearer ${token}` } });
    if (!result.ok) {
        throw new Error(await result.text());
    }

    return result.json();
}
