import { FC } from 'react'
import { PROD_HOSTNAME, WIDGET_SCRIPT_PATH } from '../utils/shared/constants';
import { AdvancedButton } from '../components/AdvancedButton';
import { NavLink, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface IProps { }

/**
* @author
* @function @InstancePage
**/

const GUIDES_LINK = 'https://beaver.codes/google-reviews/#guides'

export const AddToSitePage: FC<IProps> = (props) => {
    const { instanceId } = useParams<{ instanceId: string }>();

    const scriptSrc = PROD_HOSTNAME + WIDGET_SCRIPT_PATH;
    const snippet = `<div data-instance-id="${instanceId}">
    <script src="${scriptSrc}" async="true"></script>
</div>`

    const handleCopy = () => {
        navigator.clipboard.writeText(snippet)
        toast.success('Copied to clipboard', { icon: '🦄', autoClose: 700 })
    }

    return (
        <>
            <h3>Add to website</h3>
            <p>It's time to show off the love</p>


            <div className='my-5'>
                <h5>Copy this snippet and paste it in your website</h5>

                <pre className='snippet position-relative'>
                    {snippet}
                    <AdvancedButton icon='bi-copy' variant=''
                        className='position-absolute top-0 end-0 mt-2 me-2 border rounded p-2 shadow'
                        onClick={handleCopy}>
                        Copy
                    </AdvancedButton >
                </pre>
            </div>

            {/*Note */}
            <div className='alert alert-info mb-5'>
                <i className='bi bi-info-circle me-2' />Check our <a href={GUIDES_LINK} target='_blank' rel="noreferrer">guides <i className='bi bi-box-arrow-up-right' /></a>  on how to add the widget into your platform.
            </div>

            <NavLink to='/'>
                <AdvancedButton variant='outline-primary' icon="bi-arrow-left">Back</AdvancedButton>
            </NavLink>
        </>
    )
}