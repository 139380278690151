import { FC, useCallback, useEffect, useState } from 'react'
import { useAuth, useFirestore } from 'reactfire';
import { addDoc, collection, deleteDoc, doc, where } from "firebase/firestore";
import { COLLECTIONS, PATHS } from '../utils/shared/constants';
import { useFirebaseDoc, useFirebaseQuery } from '../hooks/firebaseHookst';
import { Button, ButtonGroup, Card, Dropdown, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Instance } from '../models/shared/Instance';
import WidgetPreview from '../components/WidgetPreview';
import { AdvancedButton } from '../components/AdvancedButton';
import { generateFunnyName } from '../utils/format';
import useConfirmModal from '../hooks/useConfirmModal';
import { toast } from 'react-toastify';
import useQueryParam from '../hooks/useQueryParams';
import { User } from '../models/shared/User';
import { getUserWidgetLimit } from '../utils/shared/logic';

interface IProps { }

/**
* @author
* @function @InstanceListPage
**/


export const InstanceListPage: FC<IProps> = (props) => {
    const currentUser = useAuth().currentUser?.uid;
    const [instances, , instancesLoaded] = useFirebaseQuery<Instance>(COLLECTIONS.INSTANCES, where("ownerId", "==", currentUser));
    const [isProcessing, setIsProcessing] = useState(false);
    const [user] = useFirebaseDoc<User>(`${COLLECTIONS.USERS}/${currentUser}`);
    const firestore = useFirestore();
    const navigate = useNavigate();
    const { emailSigningFinished } = useQueryParam();
    const [ConfirmModal, trigger] = useConfirmModal("Are you sure you want to delete this widget?");
    const userWidgetLimit = getUserWidgetLimit(user);
    const isWidgetCapacityReached = instances.length >= userWidgetLimit;
    const [showLimit, setShowLimit] = useState(false);
    const handleDelete = (instanceId: string) => {
        trigger(async () => {
            setIsProcessing(true);
            const instanceRef = doc(firestore, `${COLLECTIONS.INSTANCES}/${instanceId}`);
            await deleteDoc(instanceRef);
            setIsProcessing(false);
            toast.success("Widget deleted");
        })
    }

    const handleNewInstance = useCallback(async () => {
        if (isWidgetCapacityReached) {
            setShowLimit(true);
            return;
        }

        setIsProcessing(true);

        const newInstance: Instance = {
            name: generateFunnyName(),
            ownerId: currentUser || '',
        }
        const creationResult = await addDoc(collection(firestore, COLLECTIONS.INSTANCES), newInstance);
        navigate('/' + PATHS.INSTANCE + '/' + creationResult.id);
    }, [currentUser, firestore, isWidgetCapacityReached, navigate])

    useEffect(() => {
        if (!instancesLoaded) {
            return;
        }

        if (instances.length === 0 && emailSigningFinished) {
            handleNewInstance();
        }
    }, [instances, instancesLoaded, handleNewInstance, emailSigningFinished])

    return (
        <>
            <Modal show={showLimit} onHide={() => { setShowLimit(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Widget Limit</Modal.Title>
                </Modal.Header>
                <Modal.Body>You have reached the widget limit for your current plan, check out our other plans.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowLimit(false) }}>
                        cancel
                    </Button>
                    <Link to={PATHS.BILLING}>
                        <Button variant="primary">
                            See Billing Plans
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>

            <ConfirmModal />
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-4 '>
                <div>
                    <h3>Widget List</h3>
                    <p>Here you can maintain manage all your widgets</p>
                </div>
                <AdvancedButton variant='outline-primary' icon='bi-plus-circle'
                    processing={isProcessing}
                    onClick={handleNewInstance}
                >
                    New Widget
                </AdvancedButton>
            </div>

            {instances.map((instance, index) => (
                <Card key={index} className='my-3' >
                    <Card.Body>
                        <div className='d-flex justify-content-between mb-md-3 flex-wrap'>
                            <div className='mb-2'>
                                <Card.Title>{instance.name || ''}</Card.Title>
                                <Card.Text className='text-muted'>{instance.id}</Card.Text> {/* change later */}
                            </div>

                            <div className='dropdown'>
                                <Dropdown as={ButtonGroup} align='end'>
                                    <Link to={PATHS.ADD_TO_SITE + '/' + instance.id}>
                                        <AdvancedButton icon="bi-stars">Add to website</AdvancedButton>
                                    </Link>
                                    <Dropdown.Toggle split variant="dark " />
                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} to={PATHS.INSTANCE + '/' + instance.id}>
                                            <i className="bi bi-pencil-square me-2" />Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item disabled={isProcessing} onClick={() => { handleDelete(`${instance.id}`); }}>
                                            <i className="bi bi-trash me-2" />Delete
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>

                        <WidgetPreview forceContained instanceId={instance.id || ''} />
                    </Card.Body>
                </Card>
            ))}
        </>
    )
}
