import React, { useEffect, useState } from 'react'
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap'
import { useAuth, } from 'reactfire';
import { searchPlaces } from '../utils/api';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { AdvancedButton } from './AdvancedButton';
import { useFirebaseDoc } from '../hooks/firebaseHookst';
import { User } from '../models/shared/User';
import { COLLECTIONS } from '../utils/shared/constants';
import { Source } from '../models/shared/Source';
import { SearchPlaceCandidate } from '../models/shared/api';

const MIN_CHARS_TO_SEARCH = 3

interface Props {
    canCancel: boolean;
    onCancel: () => void;
    onLocationChosen: (candidate: SearchPlaceCandidate, source: Source) => void
}

export default function LocationSearch(props: Props) {
    const [fetching, setFetching] = React.useState(false);
    const [candidates, setCandidates] = React.useState<SearchPlaceCandidate[]>([]);
    const [error, setError] = React.useState<string | null>(null);
    const typeahheadRef = React.useRef<Typeahead>(null);
    const { currentUser } = useAuth();
    const [user] = useFirebaseDoc<User>(`${COLLECTIONS.USERS}/${currentUser?.uid}`);
    const [source, setSource] = useState<Source>(Source.GOOGLE);

    useEffect(() => typeahheadRef.current?.focus(), [])

    const handleSearch = async (query: string) => {
        setFetching(true);
        try {
            const token = await currentUser?.getIdToken();

            const result = await searchPlaces(token + '', query, source);
            setCandidates(result.candidates);
            setError(null);
        } catch (error) {
            const anyError = error as any;
            setError(anyError.message);
        }
        setFetching(false)
    };

    const handleManualChosen = () => {
        if (!user?.manual) {
            return;
        }

        const candidate: SearchPlaceCandidate = {
            place_id: user.manual.placeId,
            name: user.manual.placeName,
            formatted_address: '',
        }
        props.onLocationChosen(candidate, source);
    }

    const renderSuffix = () => {
        if (fetching) {
            return <InputGroup.Text><Spinner size='sm' /></InputGroup.Text>
        }
        if (props.canCancel) {
            return <InputGroup.Text onClick={props.onCancel} className='p-0'><AdvancedButton variant='' icon=' bi-x-lg'>Cancel</AdvancedButton></InputGroup.Text>
        }

        return <InputGroup.Text><i className="bi bi-search"></i></InputGroup.Text>
    }

    return (
        <div className='mb-3'>
            <Form.Label className=''>Search For Business</Form.Label>
            <InputGroup>
                <Form.Select size="sm" onChange={e => setSource(e.target.value as Source)} value={source} style={{
                    width: 150,
                    flex: 'unset',
                }}>
                    <option value="google">Google</option>
                    <option value="bokadirekt">Bokadirekt</option>
                </Form.Select>
                <AsyncTypeahead
                    id='search-place-input'
                    ref={typeahheadRef}
                    isLoading={fetching}
                    filterBy={() => true}
                    labelKey="name"
                    onSearch={handleSearch}
                    options={candidates}
                    minLength={MIN_CHARS_TO_SEARCH}
                    onChange={(selected) => {
                        if (selected.length > 0) {
                            props.onLocationChosen(selected[0] as SearchPlaceCandidate, source)
                        }
                    }}
                    isInvalid={!!error}
                    renderMenuItemChildren={(option) => {
                        const candidate = option as SearchPlaceCandidate;
                        return <>
                            <h4 style={{ color: 'inherit' }}>{candidate.name}</h4>
                            <p className='m-0'><i className='bi bi-geo-alt' />{candidate.formatted_address}</p>
                        </>
                    }}
                />
                {renderSuffix()}
            </InputGroup>
            {error && <Form.Text className='text-danger'>{error}</Form.Text>}

            {user?.manual && <Button variant='outline-primary' className='mt-2' onClick={handleManualChosen}>
                <span className='text-warning'>[Use Imported]</span> {user.manual.placeName}
            </Button>}
        </div>
    )
}
