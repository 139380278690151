import { doc, updateDoc } from "firebase/firestore";
import React, { FC, useEffect, useState } from 'react'
import { Button, ButtonGroup, Form, FormCheck, Spinner } from 'react-bootstrap'
import { COLLECTIONS, PATHS } from '../utils/shared/constants';
import { useFirestore } from 'reactfire';
import { useAuth, } from 'reactfire';
import { DATE_FORMATS_ORDER, DATE_FORMAT_LABELS, DateFormat, Instance } from '../models/shared/Instance';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import WidgetPreview from '../components/WidgetPreview';
import { NavLink } from 'react-router-dom';
import { AdvancedButton } from '../components/AdvancedButton';
import { ensurePlaceLoaded } from "../utils/api";
import { useFirebaseDoc } from "../hooks/firebaseHookst";
import { SearchPlaceCandidate } from "../models/shared/api";
import { Source } from "../models/shared/Source";
import PlaceInput from "../components/PlaceInput";

interface IProps { }

/**
* @author
* @function @InstancePage
**/

let autosaveTimeout: NodeJS.Timeout | null = null;
const AUTOSAVE_INTERVAL = 1000;
const SPEED_OPTIONS = [
    { value: 1, icon: 'bi-chevron-double-left' },
    { value: 0.5, icon: 'bi-chevron-left' },
    { value: -0.5, icon: 'bi-chevron-right' },
    { value: -1, icon: 'bi-chevron-double-right' },
];
const FONT_OPTIONS = [
    { label: "<Default>", value: "" },
    { "label": "Arial", "value": "Arial, sans-serif" },
    { "label": "Verdana", "value": "Verdana, sans-serif" },
    { "label": "Georgia", "value": "Georgia, serif" },
    { "label": "Times New Roman", "value": "\"Times New Roman\", Times, serif" },
    { "label": "Courier New", "value": "\"Courier New\", Courier, monospace" }
]

export const InstancePage: FC<IProps> = (props) => {
    const firestore = useFirestore()
    const { currentUser } = useAuth();
    const { instanceId } = useParams<{ instanceId: string }>();
    const [editName, setEditName] = React.useState(false);
    const [instance, setInstance] = useFirebaseDoc<Instance>(`${COLLECTIONS.INSTANCES}/${instanceId}`);
    const [previewRenderCounter, setPriviewRenderCounter] = useState(0)
    const [processing, setProcessing] = useState(false);
    const [placeInputs, setPlaceInputs] = useState<(string | undefined)[]>([undefined]);

    useEffect(() => {
        if (instance?.places?.length) {
            setPlaceInputs(instance.places.map(p => p.placeId));
        }
    }, [instance?.places])

    if (!instance) {
        return <Spinner animation="border" />
    }

    const handlePlaceChosen = async (candidate: SearchPlaceCandidate, source: Source, index: number) => {
        const places = instance.places || [];
        const newPlace = {
            placeId: candidate.place_id,
            placeName: candidate.name,
        }
        places[index] = newPlace;

        const instanceUpdate: Partial<Instance> = { places };
        await updateDoc(doc(firestore, `${COLLECTIONS.INSTANCES}/${instanceId}`), instanceUpdate);
        setInstance({
            ...instance,
            ...instanceUpdate
        });

        const token = await currentUser?.getIdToken();
        await ensurePlaceLoaded(token + '', candidate.place_id, candidate.name, source)

        setPriviewRenderCounter(prev => prev + 1);
        toast.success("Place configured!");
    }

    const handlePlaceDeleted = async (index: number) => {
        const places = instance.places || [];
        places.splice(index, 1);
        const instanceUpdate: Partial<Instance> = { places };
        await updateDoc(doc(firestore, `${COLLECTIONS.INSTANCES}/${instanceId}`), instanceUpdate);
        setInstance({
            ...instance,
            ...instanceUpdate
        });
        setPriviewRenderCounter(prev => prev + 1);
    }

    const handlePlaceUpdate = async (placeId: string, placeName: string, source: Source) => {
        setProcessing(true);

        try {
            const token = await currentUser?.getIdToken();
            await ensurePlaceLoaded(token + '', placeId, placeName, source);
            setPriviewRenderCounter(prev => prev + 1);

            toast.success('Place updated');
        } catch (error) {
            console.error(error);
            toast.error('Failed to update place');
        }

        setProcessing(false);

    }

    const autoUpdateInstance = async (instanceUpdate: Partial<Instance>) => {
        if (autosaveTimeout) {
            clearTimeout(autosaveTimeout);
        }

        const newInstance = {
            ...instance,
            ...instanceUpdate
        }
        setInstance(newInstance);

        autosaveTimeout = setTimeout(async () => {
            if (newInstance.name !== '') {

                await updateDoc(doc(firestore, `${COLLECTIONS.INSTANCES}/${instanceId}`), newInstance);
                setPriviewRenderCounter(prev => prev + 1);
                toast.info('Saved and published', { icon: "✔️", autoClose: 1000 })
            }
        }, AUTOSAVE_INTERVAL);
    }

    const nameInputWidth = (instance.name?.length || 0) + (editName ? 9 : 0);
    const minRating = instance.minRating || 1;
    const dateFormat: DateFormat = instance.dateFormat || 'DD/MM/YYYY';
    const speed = instance.speed || 1;
    const optionsDisabled = !instance.places?.length;
    const fontFamily = instance.fontFamily || '';

    return (
        <>
            <div className="d-flex justify-content-between">

                <Form onSubmit={(e) => e.preventDefault()}>
                    <h3 className='d-flex align-items-center'>
                        <Form.Control
                            value={instance.name}
                            onChange={(e) => autoUpdateInstance({ name: e.target.value })}
                            style={{ width: nameInputWidth + 'ch' }}
                            plaintext={!editName} type="text"
                            isInvalid={!instance.name} />
                        <Button variant='plain' type="submit" onClick={() => setEditName(!editName)} disabled={editName && !instance.name.length}>
                            <i className={`bi ${editName ? 'bi-check' : 'bi-pencil-square'} ms-2`}></i>
                        </Button>
                    </h3>
                </Form>


            </div>
            <p>Start configuring your widget</p>
            <div className='my-5'>

                <h5>{placeInputs.length > 1 ? 'Places' : 'PLace'}</h5>

                {placeInputs.map((placeId, index) => <PlaceInput key={index}
                    placeId={placeId}
                    onLocationChosen={(candidate, source) => handlePlaceChosen(candidate, source, index)}
                    handlePlaceUpdate={handlePlaceUpdate}
                    processing={processing}
                    canDelete={index > 0}
                    onDelete={() => handlePlaceDeleted(index)}
                />
                )}

                <AdvancedButton size="sm" icon="bi-plus" variant="outline-secondary" onClick={() => {
                    setPlaceInputs([...placeInputs, undefined])
                }}>
                    Add Place
                </AdvancedButton>

            </div>


            <div className="my-5 options" style={{ maxHeight: optionsDisabled ? 0 : 1000 }}>
                <h5>Options</h5>
                <label>Minimal rating</label>
                <div className="mb-3 d-flex align-items-center">
                    <ButtonGroup>
                        {[1, 2, 3, 4, 5].map(number => {
                            const isSelected = number === minRating;
                            return <Button size="sm" key={number}
                                onClick={() => autoUpdateInstance({ minRating: number })}
                                variant={isSelected ? 'primary' : "outline-primary"}>
                                {number}
                            </Button>
                        }
                        )}
                    </ButtonGroup>
                    <div className="ms-2">
                        {[1, 2, 3, 4, 5].map(number => <i key={number} className={`bi ${number <= minRating ? 'bi-star-fill' : 'bi-star'}`}
                            style={{ color: '#fbbc04' }}
                        />)}
                    </div>
                </div>

                <label>Date format</label>
                <div className="mb-3">

                    <ButtonGroup>
                        {DATE_FORMATS_ORDER.map(format => {
                            const isSelected = format === dateFormat;
                            return <Button key={format}
                                size="sm"
                                onClick={() => autoUpdateInstance({ dateFormat: format })}
                                variant={isSelected ? 'primary' : "outline-primary"}>
                                {DATE_FORMAT_LABELS[format]}
                            </Button>
                        })}
                    </ButtonGroup>
                </div>

                <label>Speed</label>
                <div className="mb-3">
                    <ButtonGroup>
                        {SPEED_OPTIONS.map(speedOption => {
                            const isSelected = speedOption.value === speed;
                            return <Button key={speedOption.value}
                                size="sm"
                                onClick={() => autoUpdateInstance({ speed: speedOption.value })}
                                variant={isSelected ? 'primary' : "outline-primary"}>
                                <i className={`bi ${speedOption.icon}`}></i>
                            </Button>
                        })}
                    </ButtonGroup>
                </div>

                <label>Font</label>
                <div className="mb-3">

                    <ButtonGroup>
                        {FONT_OPTIONS.map(option => {
                            const isSelected = fontFamily === option.value;
                            return <Button key={option.value}
                                style={{ fontFamily: option.value }}
                                size="sm"
                                onClick={() => autoUpdateInstance({ fontFamily: option.value })}
                                variant={isSelected ? 'primary' : "outline-primary"}>
                                {option.label}
                            </Button>
                        })}
                    </ButtonGroup>
                    <p className="text-muted">*Default font inherits font from target site</p>
                </div>


                <FormCheck
                    type="switch"
                    label="Full width"
                    className="pointer"
                    checked={!!instance.fullWidth}
                    onChange={(e) => autoUpdateInstance({ fullWidth: e.target.checked })}
                />
                <FormCheck
                    type="switch"
                    label="Show only reviews with text"
                    className="pointer"
                    checked={!!instance.textOnly}
                    onChange={(e) => autoUpdateInstance({ textOnly: e.target.checked })}
                />
                <FormCheck
                    type="switch"
                    label="Random order"
                    className="pointer"
                    checked={!!instance.randomOrder}
                    onChange={(e) => autoUpdateInstance({ randomOrder: e.target.checked })}
                />
                <FormCheck
                    type="switch"
                    label="Show source logo"
                    className="pointer"
                    checked={!!instance.showSourceLogo}
                    onChange={(e) => autoUpdateInstance({ showSourceLogo: e.target.checked })}
                />
            </div>

            <div className='my-5'>
                <h5>Preview</h5>
                <WidgetPreview key={previewRenderCounter} instanceId={instanceId + ''} />
            </div>
            <div className='d-flex justify-content-md-end justify-content-between mb-5'>
                <NavLink to='/'>
                    <AdvancedButton icon="bi-arrow-left" className='me-2' variant='outline-primary' type="button">Back</AdvancedButton>
                </NavLink>
                <NavLink to={'/' + PATHS.ADD_TO_SITE + '/' + instanceId} >
                    <AdvancedButton icon="bi-stars" className="h-100" disabled={optionsDisabled}>Add to website</AdvancedButton>
                </NavLink>
            </div>

        </>
    )
}