export interface User {
    uid: string,
    email: string,
    createdAt: Date,
    trialEndsAt: Date,
    planId?: BillingPlanId,
    planChangedAt?: Date,
    emailSent?: Record<string, Date>,
    manual?: {
        placeId: string,
        placeName: string,
        placeUrl: string,
    }
}

export type BillingPlanId = 'base' | 'business' | 'enterprise' | 'friends';

export const BILLING_PLAN_ORDER: BillingPlanId[] = ['base', 'business', 'enterprise'];
