import React from 'react'

interface Props {
    name: string,
    className?: string,
    optionClassName?: string,
    options: RadioOption[]
}

interface RadioOption {
    label: string,
    onSelected: () => void
    checked: boolean
}

function RadioButtonsGroup(props: Props) {

    return <div className={props.className}>
        {props.options.map((option, index) => <div key={index} className={`form-check ${props.optionClassName}`}>
            <input type='radio' checked={option.checked} className='form-check-input' name={props.name} id={props.name + index} onChange={option.onSelected} />
            <label onClick={option.onSelected} className='pointer form-check-label' htmlFor={props.name + index} >{option.label}</label>
        </div>)}
    </div>
}

export default RadioButtonsGroup
