import React from 'react'
import { BillingPlanConfig } from '../models/shared/BillingPlanConfig'
import { AdvancedButton } from './AdvancedButton'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BillingPlanId } from '../models/shared/User'

interface Props {
    annualy: boolean
    plan: BillingPlanConfig
    onSelected: () => void
    processing: boolean
    currentPlanId: string
}


const FEATURES: Record<BillingPlanId, string[]> = {
    'base': [
        // '1 widget',
        'Quick Installation',
        'Automatic Review Updates',
        'Review Filtering',
        'SEO Boost',
    ],
    'business': [
        '2 widgets',
        'Base plan benefits',
        'Priority Support',
        'Manual Import Requests',
    ],
    'enterprise': [
        '5 widgets',
        'Base plan benefits',
        'Business plan benefits',
        'Design Adjustment Requests'
    ],
    'friends': []
}

function PlanCard(props: Props) {
    const { plan } = props
    const planId = plan.id
    const price = (props.annualy ? (plan.prices.yearly / 12) : plan.prices.monthly);
    const [integer, decimal] = ((price / 100) + '').split('.');
    const decimalDisplay = decimal.substring(0, 2);
    const period = 'per month';
    const features = FEATURES[plan.id];
    const highlighted = planId === (props.currentPlanId || 'business');
    let buttomLabel = 'Select';
    if (props.currentPlanId) {
        buttomLabel = highlighted ? 'Current Plan' : 'Update';
    }


    const renderButton = () => {
        return <AdvancedButton
            disabled={!!props.currentPlanId}
            variant={highlighted ? 'primary' : 'outline-primary'}
            processing={props.processing} onClick={props.onSelected}>
            {buttomLabel}
        </AdvancedButton>
    }


    return <div key={planId} className="plan-card m-2">
        <h5 className="card-title">{plan?.name}</h5>
        <h3 className='price center'>$<span className='integer'>{integer}</span>.{decimalDisplay}</h3>
        <p>{period}</p>

        <div className='features w-100'>
            {features.map((feature, index) => <p key={index}>✔️ {feature}</p>)}
        </div>
        <div style={{ marginTop: 'auto' }}>

            {props.currentPlanId
                ? <OverlayTrigger
                    overlay={<Tooltip id={`tooltip`}>
                        To update or cancel your plan, please contact support.
                    </Tooltip>}
                >
                    <span>
                        {renderButton()}
                    </span>
                </OverlayTrigger>
                : renderButton()}

        </div>


    </div>
}

export default PlanCard
