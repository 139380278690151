import { User } from "../../models/shared/User";
import { TRIAL_PERIOD_DAYS } from "./constants";

export const calculateUserBilling = (user: User): [number, string] => {
    const now = new Date()
    const trialRemainsDiff = user?.trialEndsAt ? (user.trialEndsAt.getTime() - now.getTime()) : undefined;
    const remainingTrialDays = trialRemainsDiff !== undefined ? Math.ceil(trialRemainsDiff / (1000 * 3600 * 24)) : TRIAL_PERIOD_DAYS;
    const plan = user?.planId;
    return [remainingTrialDays, plan || '']
}

const WIDGET_LIMIT: Record<string, number> = {
    base: 1,
    business: 2,
    enterprise: 10,
}

const DEFAULT_LIMIT = 1;
export const getUserWidgetLimit = (user?: User | null) => {
    const plan = user?.planId || '';
    if (user?.email === 'schoffer.pavel@gmail.com') {
        return 1000;
    }

    return WIDGET_LIMIT[plan] || DEFAULT_LIMIT;
}
