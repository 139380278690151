const adjectives = ['Whimsical', 'Zany', 'Wacky', 'Silly', 'Quirky', 'Bizarre', 'Absurd', 'Eccentric', 'Hilarious'];
const nouns = ['Review-o-matic', 'Opinionator', 'Critique-o-tron', 'Feedbackinator', 'Commentarium', 'Praise-o-rama', 'Ratingator', 'Critique-a-saurus'];

export function generateFunnyName() {
    const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
    return `${randomAdjective} ${randomNoun}`;
}

export const isURL = (url: string) => {
    try {
        new URL(url);
        return true;
    } catch {
        return false;
    }
}

export const isMapsUrl = (url: string) => {
    if (!isURL(url)) {
        return false;
    }

    const fullURLPattern = /google\.[a-z]{2,3}\/maps\/place/;
    if (fullURLPattern.test(url)) {
        return true;
    }

    const shortURLPattern = /maps\.app\.goo\.gl/;
    if (shortURLPattern.test(url)) {
        return true;
    }

    return false;
}

export const formatDate = (date: Date) => {
    return date?.toLocaleString();
}

export const formatDateEpoch = (epoch: number): string => {
    const date = new Date(epoch * 1000);
    return date.toLocaleString();
}