import React, { useEffect, useState } from 'react'
import { useAuth } from 'reactfire'
import { useFirebaseDoc } from '../hooks/firebaseHookst';
import { COLLECTIONS, CONFIG_KEYS } from '../utils/shared/constants';
import { Spinner } from 'react-bootstrap';
import { BILLING_PLAN_ORDER, User } from '../models/shared/User'
import { formatDateEpoch } from '../utils/format';
import { PublicConfig } from '../models/PublicConfig';
import { ensureUserPlan, getCustomerInvoices, initiatePlanUpdate } from '../utils/api';
import useQueryParam from '../hooks/useQueryParams';
import PlanCard from '../components/PlanCard';
import RadioButtonsGroup from '../components/RadioButtonsGroup';
import { Invoice } from '../models/shared/Invoice';
import { calculateUserBilling } from '../utils/shared/logic';

interface Props { }

function BillingPage(props: Props) {
    const { currentUser } = useAuth();
    const [user] = useFirebaseDoc<User>(`${COLLECTIONS.USERS}/${currentUser?.uid}`);
    const [publicConfig] = useFirebaseDoc<PublicConfig>(`${COLLECTIONS.CONFIG}/${CONFIG_KEYS.public}`);
    const [processing, setProcessing] = React.useState(false)
    const { planUpdated } = useQueryParam()
    const [annually, setAnnually] = React.useState(true)
    const [remainingTrialDays] = calculateUserBilling(user!);
    const existingPlans = BILLING_PLAN_ORDER.filter(planId => publicConfig?.billingPlans[planId])
    const currentPlanId = user?.planId || '';
    const [invoices, setInvoices] = useState<Invoice[]>([])

    useEffect(() => {
        if (!planUpdated || !currentUser) {
            return;
        }

        (async () => {
            const token = await currentUser.getIdToken();
            await ensureUserPlan(token + '');
        })()
    }, [planUpdated, currentUser])

    useEffect(() => {
        if (!currentUser) {
            return;
        }

        (async () => {
            const token = await currentUser.getIdToken()!;
            const result = await getCustomerInvoices(token);
            const newInvoices = result.invoices as Invoice[];
            newInvoices.sort((a, b) => Number(b.created) - Number(a.created))
            setInvoices(newInvoices);
        })();
    }, [currentUser])

    if (!user) {
        return <Spinner />
    }

    const handleSelectPlan = async (planId: string) => {
        setProcessing(true)

        try {
            const token = await currentUser?.getIdToken();
            const response = await initiatePlanUpdate(token + '', planId, annually)
            const url = response.url;
            if (!url) {
                throw new Error('No url in response')
            }
            window.location.href = url

        } catch (error) {
            console.error(error)
        }


        setProcessing(false)
    }

    let planName = 'Trial';
    if (currentPlanId && publicConfig?.billingPlans[currentPlanId]) {
        planName = publicConfig.billingPlans[currentPlanId].name;
    } else if (currentPlanId) {
        planName = currentPlanId;
    }

    return <div>
        <h3>Billing</h3>
        <p>Manage your billing plan</p>

        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Current Plan: <span className='text-bold'>{planName}</span></h5>
                <p className="card-text">
                    {currentPlanId
                        ? 'Thank you for choosing our service!'
                        : <>Trial ends at: { } <span className={remainingTrialDays <= 0 ? 'text-danger' : 'text-bold'}>({remainingTrialDays} days left)</span></>
                    }
                </p>
            </div>
        </div>

        <div className="mt-5">
            <RadioButtonsGroup
                name='interval'
                className="center"
                optionClassName="m-3"
                options={[
                    { label: 'Billed annually', onSelected: () => setAnnually(true), checked: annually },
                    { label: 'Billed monthly', onSelected: () => setAnnually(false), checked: !annually }
                ]} />

            <div className='d-flex justify-content-center flex-wrap mb-3'>
                {existingPlans.map(planId => {
                    const plan = publicConfig?.billingPlans[planId]
                    if (!plan) {
                        return null;
                    }

                    return <PlanCard
                        annualy={annually}
                        currentPlanId={currentPlanId} key={planId}
                        plan={plan} onSelected={() => handleSelectPlan(planId)} processing={processing} />
                })}
            </div>

            {!!invoices.length && <>
                <h3 className='mt-4'>Invoices</h3>
                <p>Your paid invoices</p>

                <div className='d-flex flex-column justify-content-center flex-wrap mb-3'>
                    <table className="table  table-hover  justify-content-center table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Amount</th>
                                <th scope="col">Date</th>
                                <th scope="col">Receipt</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices.map((invoice, index) => (
                                <tr key={index}>
                                    <td>${(invoice.amountPaid * 0.01).toFixed(2)}</td>
                                    <td>{formatDateEpoch(Number(invoice.created))}</td>
                                    <td className='center'>
                                        <a href={invoice.hostedInvoiceUrl} target='_blank' rel="noreferrer" className='pointer'>
                                            <i className="m-0 h4 bi bi-file-earmark-text"></i>
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>}
        </div>
    </div>
}

export default BillingPage
