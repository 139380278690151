const config = {
    useEmulators: !!process.env.REACT_APP_EMULATORS,
    functions: {
        searchPlaces: 'https://searchplaces-churu6klyq-ew.a.run.app',
        ensurePlaceLoaded: 'https://ensureplaceloadedhandler-churu6klyq-ew.a.run.app',
        createPaymentLink: 'https://createpaymentlinkhandler-churu6klyq-ew.a.run.app',
        ensureUserPlan: 'https://ensureuserplanhandler-churu6klyq-ew.a.run.app',
        sendSlackMessage: 'https://sendslackmessage-churu6klyq-ew.a.run.app',
        getCustomerInvoices: 'https://getcustomerinvoices-churu6klyq-ew.a.run.app',
        updateReviews: 'https://todo',
    }
}

if (config.useEmulators) {
    config.functions = {
        searchPlaces: 'http://127.0.0.1:5001/web--reviews/europe-west1/searchPlaces',
        ensurePlaceLoaded: 'http://127.0.0.1:5001/web--reviews/europe-west1/ensurePlaceLoadedHandler',
        createPaymentLink: 'http://127.0.0.1:5001/web--reviews/europe-west1/createPaymentLinkHandler',
        ensureUserPlan: 'http://127.0.0.1:5001/web--reviews/europe-west1/ensureUserPlanHandler',
        sendSlackMessage: 'http://127.0.0.1:5001/web--reviews/europe-west1/sendSlackMessage',
        getCustomerInvoices: 'http://127.0.0.1:5001/web--reviews/europe-west1/getCustomerInvoices',
        updateReviews: "http://127.0.0.1:5001/web--reviews/europe-west1/updateReviews"
    }
}

export default config;