import React, { useEffect } from 'react'
import { WIDGET_SCRIPT_PATH } from '../utils/shared/constants'
import config from '../utils/config';

interface Props {
    instanceId: string;
    forceContained?: boolean;
}

function WidgetPreview(props: Props) {
    useEffect(() => {
        const divRef = document.querySelector(`div[data-instance-id="${props.instanceId}"]`) as HTMLDivElement;
        if (!divRef) {
            return;
        }

        const script = document.createElement('script');
        script.async = true;
        script.src = WIDGET_SCRIPT_PATH

        divRef.append(script);

        return () => {
            divRef.removeChild(script);
        }

    }, [props.instanceId])

    return <div key={props.instanceId} data-contained={!!props.forceContained} data-instance-id={props.instanceId} data-use-emulators={config.useEmulators || ''} />

}

export default WidgetPreview
