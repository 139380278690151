export enum QUERY_PARAMS {
    EMAIL_SIGNIN_FINISHED = 'email_signin_finished',
    PLAN_UPDATED = 'plan_updated',
    DEBUG = 'debug',
}

export enum LOCAL_STORAGE_KEYS {
    MAGIC_LINK_EMAIL = 'magic_link_email',
}

export enum PATHS {
    INSTANCE = 'instance',
    ADD_TO_SITE = 'add_to_site',
    BILLING = 'billing',
}

export enum COLLECTIONS {
    CONFIG = 'config',
    INSTANCES = 'instances',
    PLACES = 'places',
    REVIEWS = 'reviews',
    USERS = 'users',
}

export enum CONFIG_KEYS {
    public = 'public',
    private = 'private',
}

export enum PubSubTopics {
    backfill = 'backfill',
    update = 'update',
}

export const WIDGET_ASSET_BASE_NAME = 'web-google-reviews';
export const WIDGET_SCRIPT_PATH = `/widget/${WIDGET_ASSET_BASE_NAME}.js`
export const PROD_HOSTNAME = 'https://reviews.beaver.codes'

export const TRIAL_PERIOD_DAYS = 30;
export const TRIAL_EMAIL_WARNING_DAYS = 5

export enum EmailSubject {
    TrialWarning = "Your Reviews trial is ending soon!",
}

export enum EmailTemplate {
    TrialWarning = "web-trial-ends",
}

export const DEFAUTL_SUBJECT: Record<EmailTemplate, EmailSubject> = {
    [EmailTemplate.TrialWarning]: EmailSubject.TrialWarning,
};
